<template>
    <div>
        <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
                <template v-slot:header>
                    <h6 class="mb-0">Tạo mới</h6>
                </template>
                <b-row>
                    <b-col sm="6">
                        <b-form-group label="Tên (*)">
                            <b-form-input
                                v-model="input.name"
                                placeholder="Tên"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Code">
                            <b-form-input
                                v-model="input.code"
                                placeholder="Code"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Ảnh (*):">
                            <b-form-file
                                @change="onImageChange($event,'image')"
                                placeholder="chọn ảnh"
                                accept="image/*"
                                type="file"
                            ></b-form-file>
                            <div v-if="input.icon">
                                <img
                                    style="width: 100px;"
                                    :src="input.icon"
                                    :required="input.icon ? false : true"
                                    alt=""
                                />
                            </div>
                        </b-form-group>
                        <b-form-group label="Loại (*)">
                            <b-form-select
                                v-model="input.type_id"
                                :options="options.types"
                            ></b-form-select>
                        </b-form-group>
                        <b-form-group label="Điều hướng (*)">
                            <multiselect
                                v-model="input.direction"
                                :options="options.directions"
                                :multiple="false"
                                label="text"
                                track-by="value"
                            ></multiselect>
                        </b-form-group>
                        <b-form-group label="Hiển thị với tập Segment">
                            <multiselect
                                v-model="input.segments"
                                :options="options.segments"
                                :multiple="true"
                                label="text"
                                track-by="value"
                            ></multiselect>
                        </b-form-group>
                        <b-form-group label="Không hiển thị với tập Segment">
                            <multiselect
                                v-model="input.ignore_segments"
                                :options="options.segments"
                                :multiple="true"
                                label="text"
                                track-by="value"
                            ></multiselect>
                        </b-form-group>
                        <b-form-group label="Keywords (*)">
                            <Tags :list="input.keywords" @change="saveKeywords"></Tags>
                        </b-form-group>
                        <b-form-group label="Trạng thái (*)">
                            <b-form-select
                                v-model="input.status"
                                :options="options.status"
                            ></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-center">
                        <b-button class="mr-2" v-if="this.id == '' || this.id == null" variant="outline-primary"
                                  @click="store()"
                        >Thêm mới
                        </b-button>
                        <b-button class="mr-2" v-if="this.id != '' && this.id != null" variant="outline-primary"
                                  @click="update()"
                        >Lưu
                        </b-button>
                        <b-button class="mr-2" variant="outline-danger" @click="cancel()"
                        >Hủy
                        </b-button>
                    </b-col>
                </b-row>
            </b-card>
        </b-card-group>
    </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import "vue2-datepicker/index.css";
import CmsRepository from "@/core/repositories/cmsRepository";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Multiselect from "vue-multiselect";
import Tags from "../../../components/ads/Tags";

const WalletRunRepository = RepositoryFactory.get("WalletRun");

Vue.use(Notifications);
Vue.component("multiselect", Multiselect);
Vue.component("Tags", Tags);
export default {
    components: {
        Multiselect,
        Tags
    },
    mixins: [Common],
    data() {
        return {
            input: {
                name: null,
                code: null,
                description: null,
                icon: null,
                type_id: 0,
                direction: 2,
                status: 2,
                keywords:[],
                ignore_segments:[],
                segments:[],
            },
            id: this.$route.query.id ?? null,
            options: {
                status: [
                    {value: 1, text: "Ẩn"},
                    {value: 2, text: "Hiển thị"},
                    {value: -1, text: "Đã xóa"},
                ],
                directions:[
                    {value: 1, text: "Ẩn"},
                ],
                types:[],
                segments:[],
            },
        };
    },
    async created() {
        this.getDirections();
        this.getTypes();
    },
    async mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Config service app", route: '/tools/service-app'},
        ]);
        await this.getSegments();
        this.$nextTick(() => {
            if (this.$route.query.id != '' && this.$route.query.id != null) {
                this.getDetail(this.$route.query.id)
            }
        });
    },
    methods: {
        getTypes() {
            this.options.types = [];
            let types = [{value: 0, text: "Tất cả"}];
            CmsRepository.listServiceTypes()
                .then((response) => {
                    if (response.data.error_code) {
                        this.notifyAlert("warn", response.data.message);
                        return;
                    }

                    response = response.data.data;
                    response.map(function(val){
                        types.push({
                            value:val.id,
                            text:val.name
                        });
                    })
                    this.options.types = [...types];
                    return;
                })
                .catch(() => {
                    alert("Có lỗi xảy ra");
                });
        },
        getDirections() {
            let directions = [];
            this.options.directions = [];
            let params = {
                skip_paginate:1
            };
            CmsRepository.listRouteApp(params)
                .then((response) => {
                    if (response.data.error_code) {
                        this.notifyAlert("warn", response.message);
                        return;
                    }
                    response = response.data.data;
                    response.map(function(val){
                        directions.push({
                            value:val.id,
                            text:val.name
                        });
                    })
                    this.options.directions = [...directions];
                    return;
                })
                .catch(() => {
                    alert("Có lỗi xảy ra");
                });
        },
        getSegments() {
            let segments = [];
            this.options.segments = [];
            let params = {
                per_page:-1
            };
            CmsRepository.listSegmentAvailable(params)
                .then((response) => {
                    if (response.data.error_code) {
                        this.notifyAlert("warn", response.message);
                        return;
                    }
                    response = response.data.data;
                    response.map(function(val){
                        segments.push({
                            value:'SEGMENT_'+val.id.toString(),
                            text:'['+val.id.toString()+'] '+val.title
                        });
                    })
                    this.options.segments = [...segments];
                    return;
                })
                .catch(() => {
                    alert("Có lỗi xảy ra");
                });
        },
        saveKeywords(list){
            this.input.keywords = list;
        },
        cancel() {
            return this.$router.push({name: "serviceApp"});
        },
        validateStoreForm() {
            if (this.input.name === "" || this.input.name === null) {
                this.notifyAlert("warn", "Tên không được để trống");
                return false;
            }
            if (this.input.code === "" || this.input.code === null) {
                this.notifyAlert("warn", "Code không được để trống");
                return false;
            }
            // if (this.input.description === "" || this.input.description === null) {
            //     this.notifyAlert("warn", "Mô tả không được để trống");
            //     return false;
            // }
            if (this.input.icon === "" || this.input.icon === null) {
                this.notifyAlert("warn", "Ảnh không được để trống");
                return false;
            }
            return true;
        },
        store() {
            if (!this.validateStoreForm()) {
                return;
            }
            let inputParam = {...this.input};

            inputParam.segments = [];
            this.input.segments.map( function(value) {
                inputParam.segments.push(value.value)
            })

            inputParam.ignore_segments = [];
            this.input.ignore_segments.map( function(value) {
                inputParam.ignore_segments.push(value.value)
            })

            inputParam.direction_id = inputParam.direction.value;
            this.$bus.$emit("show-loading", true);
            CmsRepository.storeServiceApp(inputParam)
                .then((response) => {
                    if (response) {
                        this.notifyAlert("success", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return this.$router.push({name: "serviceApp"});
                    } else {
                        this.notifyAlert("warn", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return false;
                    }
                })
                .catch(() => {
                    alert("Có lỗi xảy ra");
                });
        },
        update() {
            if (!this.validateStoreForm()) {
                return;
            }
            let inputParam = {...this.input};

            inputParam.segments = [];
            this.input.segments.map( function(value) {
                inputParam.segments.push(value.value)
            })
            inputParam.ignore_segments = [];
            this.input.ignore_segments.map( function(value) {
                inputParam.ignore_segments.push(value.value)
            })
            inputParam.direction_id = inputParam.direction.value;
            this.$bus.$emit("show-loading", true);
            inputParam.id = this.id
            CmsRepository.updateServiceApp(this.id, inputParam)
                .then((response) => {
                    if (response.data.error_code == 1) {
                        // this.notifyAlert("success", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return this.$router.push({name: "serviceApp"});
                    } else {
                        this.notifyAlert("success", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return this.$router.push({name: "serviceApp"});
                    }
                })
                .catch(() => {
                    alert("Có lỗi xảy ra");
                });
        },
        async getDetail(id) {
            this.$bus.$emit("show-loading", true);
            setTimeout(() => {
                CmsRepository.detailServiceApp(id)
                    .then((response) => {
                        if (response.data.error_code !== 0) {
                            this.notifyAlert("warn", response.data.message);
                            this.$bus.$emit("show-loading", false);
                            return false;
                        }

                        let result = response.data.data;
                        let optionSegments = this.options.segments;
                        this.input.name = result.name;
                        this.input.code = result.code;
                        // this.input.description = result.description;
                        this.input.icon = result.icon;
                        this.input.type_id = result.type_id;
                        this.input.direction = result.direction ? {
                            'value':result.direction.id,
                            'text' : result.direction.name
                        } : null;
                        this.input.status = result.status;
                        this.input.keywords = result.keywords;
                        result.segments = result.segments ? JSON.parse(result.segments) : [];
                        let dataSegments = [];
                        result.segments.map(function(segment){
                            optionSegments.filter((item) => {
                                if (item.value == segment) {
                                    dataSegments.push(item)
                                }
                            });
                        });
                        this.input.segments = dataSegments;

                        let dataIgnoreSegments = [];
                        result.ignore_segments = result.ignore_segments ? JSON.parse(result.ignore_segments) : [];
                        result.ignore_segments.map(function(segment){
                            optionSegments.filter((item) => {
                                if (item.value == segment) {
                                    dataIgnoreSegments.push(item)
                                }
                            });
                        });

                        this.input.ignore_segments = dataIgnoreSegments;

                        this.$bus.$emit("show-loading", false);
                    })
                    .catch((error) => {
                        alert(error);
                        this.$bus.$emit("show-loading", false);
                    });
            },4000)
        },
        onImageChange(e, type, position) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }
            let fileSize = files[0].size / 1024 / 1024; // in MiB
            if (fileSize > 2) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Chọn ảnh không vượt quá 2MB",
                });
                return false;
            }
            this.images = files[0];
            this.uploadMedia(type, position);
            //this.createImage(files[0]);
        },
        uploadMedia: async function () {
            this.$bus.$emit("show-loading", true);
            const formData = new FormData();
            formData.append("file", this.images);

            WalletRunRepository.uploadMedia(formData)
                .then((response) => {
                    this.$bus.$emit("show-loading", false);
                    if (1 === response.data.error_code) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: response.data.message,
                        });
                    } else {
                        this.input.icon = response.data.data.link;
                    }
                })
                .catch((response) => {
                    this.$bus.$emit("show-loading", false);
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: response.data.message,
                    });
                });
        },
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


